import styled, { withTheme } from "styled-components";
import { useRecoilValue } from "recoil";
import { CloseButton, H2, H3, Link } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import userState from "../../state/user";
import ProgressBar from "../ProgressBar";
import useCopy from "../../hooks/useCopy";
import { close } from "../../utils";
import { logoutUser } from "../../httpapi";

const ContentWrapper = styled.div`
  display: flex;
  padding: 6px 0 24px;
  @media (min-width: 900px) {
    justify-content: space-between;
  }
`;

const LogoutWrapper = styled.div`
  margin: -20px -25px 14px;
  background-color: ${({ theme }) => theme.colors.light};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.8;
  padding: 10px 24px 8px;
  @media (min-width: 900px) {
    padding: 4px 24px;
  }
`;

const TextWrapper = styled.div``;

const CloseWrapper = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: flex;
    align-items: center;
  }
`;

const StepNumber = styled(H3)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.secondary};
`;

const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
`;

const ProgressWrapper = styled.div`
  padding-bottom: 24px;
  @media (min-width: 900px) {
    border-top: ${({ theme, showBorder }) =>
      showBorder ? `1px solid ${theme.colors.border}` : 0};
    width: calc(100% + 50px);
    margin-left: -25px;
  }
`;

const Header = ({ currentStep, totalSteps, title, theme }) => {
  const showProgressBar = !!currentStep;
  const userData = useRecoilValue(userState);
  const [stepText, logoutText, logoutLinkText] = useCopy([
    "bookNow.steps.stepNumber",
    "bookNow.panelHeader.logout",
    "bookNow.panelHeader.logoutLink",
  ]);
  const stepNumber = swapCopyVariables(stepText, { NUMBER: currentStep });

  const handleLogout = async () => {
    const { success } = await logoutUser();
    if (success) {
      close();
    }
  };

  return (
    <>
      {userData.signedIn && (
        <LogoutWrapper>
          {swapCopyVariables(logoutText, {
            NAME: userData.name,
            LOGOUT_LINK: <Link onClick={handleLogout}>{logoutLinkText}</Link>,
          })}
        </LogoutWrapper>
      )}
      <ContentWrapper>
        <TextWrapper>
          {currentStep && <StepNumber>{stepNumber}</StepNumber>}
          <Title>{title}</Title>
        </TextWrapper>
        <CloseWrapper>
          <CloseButton
            close={close}
            width={"14px"}
            height={"14px"}
            fill={theme.colors.default}
            id="stepform-close"
            type="button"
          />
        </CloseWrapper>
      </ContentWrapper>
      <ProgressWrapper showBorder={!showProgressBar}>
        {showProgressBar && (
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        )}
      </ProgressWrapper>
    </>
  );
};

export default withTheme(Header);
