import { selector } from "recoil";
import providerState from "./provider";

const fetchCopy = async (countryCode, languageCode) => {
  const previewMode = document.cookie.indexOf("sussex-app-preview=1") >= 0;
  const viewingMode = previewMode ? "draft" : "published";
  const url = `${
    process.env.REACT_APP_COPY_GROUPS_CDN_URL
  }/booknow-app/psychologytoday/therapists/${viewingMode}/${countryCode.toLowerCase()}/${languageCode.toLowerCase()}.json`;
  try {
    const response = await fetch(url);
    const rawCopy = await response.json();
    const copy = rawCopy.reduce((accum, curr) => {
      accum[curr.key] = curr.value;
      return accum;
    }, {});
    return copy;
  } catch (err) {
    throw new Error("Failed to fetch copy", err);
  }
};

const copy = selector({
  key: "Copy", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  get: ({ get }) => {
    const { countryCode, languageCode } = get(providerState);
    return fetchCopy(countryCode, languageCode);
  },
});

export default copy;
