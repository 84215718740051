import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Link, Button, ErrorBox, Pill } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { formatTimeRange } from "@sussex/react-kit/conversations";
import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { formState, stepState, summaryState, STEPS } from "../state";
import providerState from "../../../state/provider";
import userState, {
  activeRequests as requestHistoryState,
  locale as localeState,
} from "../../../state/user";
import useCopy from "../../../hooks/useCopy";
import Arrow from "../../../assets/Arrow.js";
import SignInFlow from "../../SignIn/Flow";
import X from "../../../assets/X";
import Prompt from "../Prompt";
import PreviousRequests from "../../PreviousRequests";

export const formTimeState = selector({
  key: "FormData.Time",
  get: ({ get }) => get(formState).time,
  set: ({ set }, newValue) => set(formState, s => ({ ...s, time: newValue })),
});

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  border-radius: 10px;
`;

const Break = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  margin: 24px 0;
`;

const XWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const AccountTextWrapper = styled.div`
  margin-top: 16px;
  line-height: 22px;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const AccountTextHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const AccountTextBody = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
`;

const NextButton = styled(Button)`
  width: 100%;
  height: 56px;
`;

const TimeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 24px;
`;

const PillWrapper = styled.div`
  * {
    margin: 16px 0;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Date = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 25px;
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.colors.border : theme.colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Prev = ({ onClick, disabled }) => {
  const theme = useTheme();

  return (
    <ArrowWrapper onClick={disabled ? null : onClick} disabled={disabled}>
      <Arrow
        direction="left"
        color={disabled ? theme.colors.border : theme.colors.dark}
      />
    </ArrowWrapper>
  );
};

const Next = ({ onClick }) => {
  const theme = useTheme();

  return (
    <ArrowWrapper onClick={onClick}>
      <Arrow direction="right" color={theme.colors.dark} />
    </ArrowWrapper>
  );
};

const TimeSlot = styled(Pill)`
  text-transform: lowercase;
`;

const NoTimeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 24px;
  text-align: center;
`;

const NoTimesAvailable = () => {
  const [noneAvailable] = useCopy(["bookNow.steps.time.noneAvailable"]);
  return <NoTimeWrapper>{noneAvailable}</NoTimeWrapper>;
};

const ConfirmTime = ({ next, disabled }) => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [time, setTime] = useRecoilState(formTimeState);
  const userData = useRecoilValue(userState);
  const locale = useRecoilValue(localeState);
  const isSummary = useRecoilValue(summaryState);
  const setCurrentStep = useSetRecoilState(stepState);
  const { timezone } = useRecoilValue(providerState);
  const start = new window.Date(time);
  const stop = new window.Date(time + 60 * 60 * 1000);
  const [signInText, nextText, haveAccountText, signInWithAccountText] =
    useCopy([
      "bookNow.signIn.signInLink",
      "bookNow.steps.next",
      "bookNow.steps.time.haveAccount",
      "bookNow.steps.time.signInWithAccount",
    ]);

  const cancel = () => {
    //navigate back
    setTime("");
  };

  const signInNext = () => {
    setShowSignIn(false);
  };

  const SignInLink = () => (
    <Link onClick={() => setShowSignIn(true)}>{signInText}</Link>
  );

  const Next = ({ disabled }) => (
    <div>
      <NextButton disabled={disabled} onClick={next} type="submit">
        {nextText}
      </NextButton>
      {(!userData.signedIn || !userData.verified) && (
        <AccountTextWrapper>
          <AccountTextHeader>{haveAccountText}</AccountTextHeader>
          <AccountTextBody>
            {swapCopyVariables(signInWithAccountText, {
              LINK: <SignInLink />,
            })}
          </AccountTextBody>
        </AccountTextWrapper>
      )}
    </div>
  );

  const ConfirmFooter = () => {
    if (showSignIn) {
      return <SignInFlow next={signInNext} />;
    }

    if (userData.signedIn && userData.verified && !isSummary) {
      return (
        <PreviousRequests
          disabled={disabled}
          onSkip={next}
          onSelect={() => {
            setCurrentStep(STEPS.SUMMARY);
          }}
        />
      );
    }

    return <Next />;
  };

  return (
    <Wrapper>
      <Pill selected={true} onClick={cancel}>
        <ConfirmTimeWrapper>
          <span>
            {start.toLocaleDateString(locale, {
              day: "numeric",
              month: "long",
            })}
            {", "}
            {formatTimeRange(start, stop, timezone, locale)}
          </span>
          <XWrapper>
            <X />
          </XWrapper>
        </ConfirmTimeWrapper>
      </Pill>
      <Break />
      <ConfirmFooter />
    </Wrapper>
  );
};

const TimeBox = ({ time = null, onSelect }) => {
  const { availability, timezone } = useRecoilValue(providerState);
  const locale = useRecoilValue(localeState);
  const startDate = time ? new window.Date(time) : new window.Date();
  const [date, setDate] = useState(startDate);
  const dayDifference = Math.round(
    (date.getTime() - new window.Date().getTime()) / (1000 * 3600 * 24),
  );
  const weekNum = Math.floor(dayDifference / 7);
  const dateProviderTz = new window.Date(
    date.toLocaleString(locale, { timeZone: timezone }),
  );
  const dayOfWeek = days[dateProviderTz.getDay()];
  const availableTimes = availability[dayOfWeek];
  const prevDisabled =
    date.toISOString().split("T")[0] ===
    new window.Date().toISOString().split("T")[0];
  const prevDay = () => {
    const prev = new window.Date(date);
    prev.setDate(prev.getDate() - 1);
    setDate(prev);
  };
  const nextDay = () => {
    const prev = new window.Date(date);
    prev.setDate(prev.getDate() + 1);
    setDate(prev);
  };
  const minDate = new window.Date();
  minDate.setTime(minDate.getTime() + 2 * 60 * 60 * 1000);
  const slots = availableTimes
    // Turn timestamps into Dates
    .map(ts => {
      const from = new window.Date(ts.from * 1000);
      const to = new window.Date(ts.to * 1000);

      if (weekNum > 0) {
        from.setDate(from.getDate() + weekNum * 7);
        to.setDate(to.getDate() + weekNum * 7);
      }

      return { from, to };
    })
    // Remove any Dates less than two hours from now
    .filter(d => d.from.getTime() > minDate.getTime());

  return (
    <TimeWrapper>
      <Heading>
        <Prev onClick={prevDay} disabled={prevDisabled} />
        <Date>
          {date.toLocaleDateString(locale, {
            weekday: "long",
            day: "numeric",
            month: "long",
            timeZone: timezone,
          })}
        </Date>
        <Next onClick={nextDay} />
      </Heading>
      <PillWrapper>
        {slots.length ? (
          slots.map(s => {
            return (
              <TimeSlot
                selected={s.from.getTime() === time}
                onClick={() => onSelect(s.from.getTime())}
                key={`${s.from}${s.to}`}
              >
                {formatTimeRange(s.from, s.to, timezone, locale)}
              </TimeSlot>
            );
          })
        ) : (
          <NoTimesAvailable />
        )}
      </PillWrapper>
    </TimeWrapper>
  );
};

const ErrorBoxWrapper = styled.div`
  margin-bottom: 16px;
  a {
    color: inherit;
  }
`;

const Time = ({ next }) => {
  const [time, setTime] = useRecoilState(formTimeState);
  const handleTimeSelect = selectedTime => {
    setTime(selectedTime);
  };
  const [prompt, tooManyRequestsError, tooManyRequestsErrorLink] = useCopy([
    "bookNow.steps.time.prompt",
    "bookNow.tooManyRequestsError.message",
    "bookNow.tooManyRequestsError.link",
  ]);
  const showTimePicker = time === "";

  const userData = useRecoilValue(userState);
  const requestHistory = useRecoilValue(requestHistoryState);
  const tooManyRequests = requestHistory && requestHistory.length >= 3;

  return (
    <div>
      {tooManyRequests && !showTimePicker && (
        <ErrorBoxWrapper>
          <ErrorBox>
            {swapCopyVariables(tooManyRequestsError, {
              NUM_REQUESTS: requestHistory.length,
              CLIENT_EMAIL: userData.email,
              LINK_TEXT: (
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/requests/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {tooManyRequestsErrorLink}
                </a>
              ),
            })}
          </ErrorBox>
        </ErrorBoxWrapper>
      )}
      <Prompt bold>{prompt}</Prompt>
      {showTimePicker ? (
        <TimeBox time={time} onSelect={handleTimeSelect} />
      ) : (
        <ConfirmTime next={next} disabled={tooManyRequests} />
      )}
    </div>
  );
};

export default Time;
