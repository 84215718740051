import { Suspense, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import StepForm from "../components/StepForm";
import BackgroundImage from "../components/BackgroundImage";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import userState from "../state/user";
import { getCurrentUser } from "../httpapi";
import MetricProvider from "../providers/MetricProvider";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Account for border radius: */
  padding-bottom: 10px;
  margin-bottom: -10px;
  @media (min-width: 900px) {
    padding-bottom: 0;
    margin-bottom: 0;
    height: 100%;
  }
`;

const HeaderWrapper = styled.div`
  height: 160px;
  @media (min-width: 900px) {
    height: auto;
  }
`;

const FormWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  @media (min-width: 900px) {
    flex: 1 0 auto;
  }
`;

const BackgroundFallback = () => {
  return <HeaderWrapper />;
};

const FormFallback = () => {
  return <Loading />;
};

export default function LandingView() {
  const setUserState = useSetRecoilState(userState);

  useEffect(() => {
    const loginCheck = async () => {
      const user = await getCurrentUser();

      if (user) {
        setUserState({
          signedIn: true,
          verified: user.emailVerified,
          username: user.email,
          name: user.name,
          email: user.email,
          phone: user.phoneNumber,
        });
      }
    };

    loginCheck();
  }, [setUserState]);

  return (
    <Wrapper>
      <MetricProvider>
        <BackgroundWrapper>
          <BackgroundImage />
          <Suspense fallback={<BackgroundFallback />}>
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <Footer />
          </Suspense>
        </BackgroundWrapper>
        <FormWrapper>
          <Suspense fallback={<FormFallback />}>
            <StepForm />
          </Suspense>
        </FormWrapper>
      </MetricProvider>
    </Wrapper>
  );
}
